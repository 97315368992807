<template>
    <div :title="title" role="button">
        <BoxIcon v-if="vod"/>
        <EllipsisIcon v-if="upcoming"/>
        <podcast-icon v-if="live" class="live"/>
    </div>
</template>

<script>
  import BoxIcon from "../../../assets/img/boxIcon";
  import {STREAM_STATUS} from "@/const";
  import EllipsisIcon from "../../../assets/img/EllipsisIcon";
  import PodcastIcon from "../../../assets/img/PodcastIcon";
  export default {
    name: "EventStatus",
    components: {PodcastIcon, EllipsisIcon, BoxIcon},
    props:{
      event: Object
    },
    computed: {
      live(){
        return STREAM_STATUS.live === this.event.stream_status
      },
      vod(){
        return STREAM_STATUS.vod === this.event.stream_status
      },
      upcoming(){
        return STREAM_STATUS.upcoming === this.event.stream_status
      },
      title(){
        switch (this.event.stream_status) {
          case STREAM_STATUS.live:
            return this.$t('live')
          case STREAM_STATUS.vod:
            return this.$t('vod')
          case STREAM_STATUS.upcoming:
            return this.$t('Coming')
          default:
            return this.$t('Coming')
        }
      }
    }
  }
</script>
<style >
  .live{
    color: var(--theme-link-accent)
  }
</style>